.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.read-more-link {
  cursor: pointer !important;
}

.member {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.image-slot {
  align-self: unset !important;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.member-hero-text {
  overflow: unset !important;
}

.section-title {
  border-top: 1px solid gray;
    width: 80%;
    border-bottom: 1px solid gray;
    justify-content: center;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: rgb(13, 26, 38);
    border-color: rgb(13, 26, 38);
}

.action-link {
  cursor: pointer !important;
}

p.action-link,.action-link > p {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.page-header {
  border-color: rgb(13, 26, 38) !important;
  border-bottom: 1px solid !important;
  position: sticky !important;
  top: 0px !important;
  z-index: 1000 !important;
}

.page-footer {
  margin-top: 2rem;
  border-top: 1px solid;
  padding: 1rem;
  font-size: 12px;
  text-align: center;
  width: 80vw;
  align-self: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
